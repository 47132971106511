import { createContext, useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  // https://stackoverflow.com/questions/61117608/how-do-i-set-system-preference-dark-mode-in-a-react-app-but-also-allow-users-to

  const [userSetDarkMode, setUserSetDarkMode] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  let prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    if (!userSetDarkMode) {
      setIsDarkTheme(prefersDarkMode);
    }
  });

  const toggleTheme = () => {
    setUserSetDarkMode(true);
    setIsDarkTheme((prevState) => {
      if (prevState === false) {
        return true;
      } else {
        return false;
      }
    });
  };

  const value = { toggleTheme, isDarkTheme };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
