import { createContext, useState } from "react";

export const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");

  const value = { make, setMake, model, setModel };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export default SearchProvider;
