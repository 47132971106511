import { useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import dynamic from "next/dynamic";

// MUI
// import { Box } from "@mui/material";

// Context maintains theme settings across pages
import { ThemeContext } from "./ThemeProvider";

// Components
// import Navbar from "./navBar";
const Navbar = dynamic(() => import("./navBar"));
const FooterBar = dynamic(() => import("./footerBar"));

const Theme = (props) => {
  const { toggleTheme, isDarkTheme } = useContext(ThemeContext);

  const handleThemeChange = (e) => {
    toggleTheme();
  };

  // Define theme settings
  const lightMode = createTheme({
    palette: {
      primary: {
        main: "#fff;",
      },
      background: {
        default: "#f5f5f5",
        secondary: "#e6e6e6",
      },
      mode: "light",
    },
  });

  // https://mui.com/customization/dark-mode/
  const darkMode = createTheme({
    palette: {
      mode: "dark",
      background: {
        secondary: "#595959",
      },
    },
  });

  const themes = {
    false: lightMode,
    true: darkMode,
  };

  const rootStyles = {
    bgcolor: "primary",
    padding: "0",
    color: "text.primary",
    minHeight: "75vh",
  };

  return (
    <ThemeProvider theme={themes[isDarkTheme]}>
      <CssBaseline enableColorScheme />
      <Navbar className="AppBar" isDarkTheme={isDarkTheme} handleThemeChange={handleThemeChange} />
      <div style={rootStyles}>{props.children}</div>
      <FooterBar />
    </ThemeProvider>
  );
};
export default Theme;
